export const storeUTMParams = () => {
  if (typeof window === 'undefined') return

  const params = new URLSearchParams(window.location.search)
  const keys = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ]

  keys.forEach((key) => {
    const value = params.get(key)
    if (value) {
      sessionStorage.setItem(key, value)
    }
  })
}

export const getStoredUTMParams = () => ({
  utmSource: sessionStorage.getItem('utm_source') || '',
  utmMedium: sessionStorage.getItem('utm_medium') || '',
  utmCampaign: sessionStorage.getItem('utm_campaign') || '',
  utmContent: sessionStorage.getItem('utm_content') || '',
  utmTerm: sessionStorage.getItem('utm_term') || '',
})
