/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import wrapPage from '@wrappers/wrap-page'
import wrapRoot from '@wrappers/wrap-root'
import {
  scrollToCategory,
  hasCategoryHash,
  scrollToEl,
  storeUTMParams,
} from '@utils'

export const wrapPageElement = wrapPage
export const wrapRootElement = wrapRoot

window.page = window.page || {}
window.page.path = window.location.pathname

export const shouldUpdateScroll = ({
  routerProps: {
    location: { hash },
  },
}) => {
  if (hash) {
    if (hasCategoryHash(hash)) {
      scrollToCategory(hash, 'smooth')
      return false
    } else {
      scrollToEl(hash, 'smooth')
      return false
    }
  } else {
    window.scrollTo(0, 0)
    return false
  }
}

export const onRouteUpdate = ({ location: { hash, search } }) => {
  if (search) {
    storeUTMParams()
  }
  if (hash) {
    if (hasCategoryHash(hash)) {
      scrollToCategory(hash)
      return false
    } else {
      scrollToEl(hash)
      return false
    }
  }
}
